import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../Context/UserContext";
import CategoryData from "./CategoryData/CategoryData";
import { CategoryContext } from "../../../Context/CategoryContext";
import axios from "axios";
import CategoryLoading from "./CategoryLoading/CategoryLoading";
function Category() {
  const { baseUrl, token, setToken, setUserData } = useContext(UserContext);
  const [categories, setCategories] = useState([]);
  const [categoriesLoading, setCategoriesLoading] = useState(false);

  const getAllCategories = async () => {
    setCategoriesLoading(true);
    const { data } = await axios.get(`${baseUrl}/category?sort=-createdAt`);
    console.log(data.categories);
    setCategories(data.categories);
    setCategoriesLoading(false);
  };

  useEffect(() => {
    getAllCategories();
  }, []);

  return (
    <>
      {categories?.length == 0 && !categoriesLoading ? (
        <div className="size-full flex items-center justify-center">
          <p className="p-5 text-lg font-medium bg-white rounded-lg">
            There is no Category till now
          </p>
        </div>
      ) : (
        <div className="p-5">
          <table className="bg-white w-[90%] mx-auto rounded-md overflow-hidden">
            <thead className="grid grid-cols-4 gap-10 items-center justify-between w-full">
              <th className="py-3 col-span-1 border-b-2 flex items-center justify-center">
                {" "}
                <td>Name in English</td>
              </th>
              <th className="py-3 col-span-1 border-b-2 flex items-center justify-center">
                <td>Image</td>
              </th>
              <th className="py-3 col-span-1 border-b-2 flex items-center justify-center">
                {" "}
                <td>SubCategories</td>
              </th>
              <th className="py-3 bg-profileColor col-span-1 border-b-2 flex items-center justify-center">
                {" "}
                <td>Action</td>
              </th>
            </thead>
            <tbody className=" w-full">
              {!categoriesLoading ? (
                categories.map((category) => (
                  <CategoryData
                    category={category}
                    length={categories.length}
                    indexOfCategory={categories.indexOf(category)}
                  />
                ))
              ) : (
                <CategoryLoading />
              )}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
}

export default Category;
