import React, { useContext, useState } from "react";
import imageForms from "../../../assets/imageFrorm.jpg"
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { AiOutlineCaretDown, AiOutlineCaretUp } from "react-icons/ai";
import { UserContext } from "../../../Context/UserContext";

function Join() {
  const [isOpened, setIsOpened] = useState(false);
  const [Choosed, setChoosed] = useState("");
  const [error, setError] = useState("");
const {setRegisterData}=useContext(UserContext)
  const navigate = useNavigate();
  const initialValues = {
    userName: "",
    email: "",
    password: "",
    phone: "",
    address: "",
  };

  const validationSchema = Yup.object({
    userName: Yup.string()
      .required("User name is required")
      .min(2, "the minlengt of user name  is 2")
      .max(20, "the maxlength of user name is 20"),
    email: Yup.string()
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "In-valid email"
      )
      .required("email is required"),
    password: Yup.string()
      .min(8, "the minlengt of password is 8")
      .matches(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
        "password has to contain uppercase letter and lowercase letter and number and special character "
      )
      .required("Password is required"),
    phone: Yup.string().matches(
      /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
      "In-valid phone"
    ),
    address: Yup.string().min(3, "the minlengt of address is 3"),
  });

  async function join(values) {
    if (!Choosed) {
      setError("You've to choose a type");
      return 0;
    }
    setRegisterData(values);
    if (Choosed == "Customer") {
      navigate("/customerRegister");
    } else if (Choosed == "Business") {
      navigate("/businessRegister");
    } else if (Choosed == "Individual") {
      navigate("/individualRegister");
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: join,
  });
  return (
    <section className="shadow-lg">
      <div className="grid grid-cols-12 px-5 sm:px-10 lg:px-0">
        {/* Left Side (Image) */}
        <div className="hidden lg:block lg:col-span-4">
          <img src={imageForms} alt="join" className="w-full" />
        </div>

        {/* Right Side (Form) */}
        <div className="col-span-12 lg:col-span-8 flex flex-col justify-center gap-6 px-5 py-10 lg:py-0">
          <h3 className="text-xl font-bold mb-3 text-main text-center lg:text-left">
            Join
          </h3>
          <form
            className={`text-socend flex flex-col w-full`}
            onSubmit={formik.handleSubmit}
          >
            <div className="flex gap-4">
              <div className="w-full">
                <label
                  htmlFor="userName"
                  className="font-medium text-sm md:text-base"
                >
                  User Name
                </label>
                <input
                  type="text"
                  id="userName"
                  name="userName"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.userName}
                  placeholder="User Name"
                  className={`mt-1  ${
                    formik.errors.userName && formik.touched.userName
                      ? ""
                      : "mb-3"
                  } bg-white font-medium outline-none shadow-2xl py-2 px-4 w-full rounded-md`}
                />
                {formik.errors.userName && formik.touched.userName ? (
                  <div className=" mb-3 rounded-md text-red-400 text-lg font-medium px-3 w-full">
                    {formik.errors.userName}
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div className="w-full">
                <label
                  htmlFor="phone"
                  className="font-medium text-sm md:text-base"
                >
                  Phone Number
                </label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.phone}
                  placeholder="+1 (555) 000-0000"
                  className={`mt-1  ${
                    formik.errors.userName && formik.touched.userName
                      ? ""
                      : "mb-3"
                  } bg-white font-medium outline-none shadow-2xl py-2 px-4 w-full rounded-md`}
                />
                {formik.errors.phone && formik.touched.phone ? (
                  <div className=" mb-3 rounded-md text-red-400 text-lg font-medium px-3 w-full">
                    {formik.errors.phone}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div className="w-full">
              <label
                htmlFor="email"
                className="font-medium text-sm md:text-base"
              >
                E-mail Address
              </label>
              <input
                type="email"
                placeholder="E-mail Address"
                id="email"
                name="email"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.email}
                className={`mt-1 ${
                  formik.errors.email && formik.touched.email ? "" : "mb-3"
                } bg-white font-medium outline-none shadow-2xl py-2 px-4  w-full rounded-md`}
              />
              {formik.errors.email && formik.touched.email ? (
                <div className=" mb-3 rounded-md text-red-400 text-lg font-medium px-3 w-full">
                  {formik.errors.email}
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="w-full">
              <label
                htmlFor="password"
                className="font-medium text-sm md:text-base"
              >
                Password
              </label>
              <input
                className={`mt-1 ${
                  formik.errors.password && formik.touched.password
                    ? ""
                    : "mb-3"
                } bg-white font-medium outline-none shadow-2xl py-2 px-4  w-full rounded-md`}
                id="password"
                name="password"
                type="password"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.password}
              />
              {formik.errors.password && formik.touched.password ? (
                <div className=" mb-3 rounded-md text-red-400 text-lg font-medium px-3 w-full">
                  {formik.errors.password}
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="w-full">
              <label
                htmlFor="address"
                className="font-medium text-sm md:text-base"
              >
                Address (optional)
              </label>
              <input
                className={`mt-1 ${
                  formik.errors.address && formik.touched.address ? "" : "mb-3"
                } bg-white font-medium outline-none shadow-2xl py-2 px-4  w-full rounded-md`}
                id="address"
                name="address"
                type="text"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.address}
                placeholder="Address"
              />
              {formik.errors.address && formik.touched.address ? (
                <div className=" mb-3 rounded-md text-red-400 text-lg font-medium px-3 w-full">
                  {formik.errors.address}
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="col-span-full lg:col-span-4">
              <label
                htmlFor="address"
                className="font-medium text-sm md:text-base"
              >
                Choose
              </label>
              <label
                onClick={() => setIsOpened(!isOpened)}
                className={`py-2 px-4 mt-1 block text-lg select-none w-full outline-none bg-white shadow-2xl${
                  isOpened ? "border-main" : ""
                }  duration-300 relative font-medium ${
                  Choosed ? "text-black" : "text-gray-400"
                } text-start `}
              >
                {!Choosed ? "Choose a type" : Choosed}

                {isOpened ? (
                  <AiOutlineCaretUp className="text-lg absolute top-1/2 text-main -translate-y-1/2 right-[5%]" />
                ) : (
                  <AiOutlineCaretDown
                    className={`text-lg absolute top-1/2 text-black -translate-y-1/2 right-[5%]`}
                  />
                )}

                <div
                  className={`${
                    isOpened ? "block" : "hidden"
                  } absolute  p-2    flex flex-col gap-1 border-2 bg-white left-0 top-full right-0 border-main text-center`}
                >
                  <div
                    onClick={() => {
                      setChoosed("Individual");
                    }}
                    className="py-1 rounded-md cursor-pointer text-main hover:text-white hover:bg-main font-medium duration-300"
                  >
                    Individual
                  </div>
                  <div
                    onClick={() => {
                      setChoosed("Business");
                    }}
                    className="py-1 rounded-md cursor-pointer text-main hover:text-white hover:bg-main font-medium duration-300"
                  >
                    Business
                  </div>
                  <div
                    onClick={() => {
                      setChoosed("Customer");
                    }}
                    className="py-1 rounded-md cursor-pointer text-main hover:text-white hover:bg-main font-medium duration-300"
                  >
                    Customer
                  </div>
                </div>
              </label>
              {error ? (
                <div className=" mb-3 rounded-md text-red-400 text-lg font-medium px-3 w-full">
                  {error}
                </div>
              ) : (
                ""
              )}
            </div>

            <button
              type="submit"
              className="w-full md:w-1/2 mt-4 outline-none text-white font-medium bg-main rounded-lg hover:bg-[#ffb13b] duration-300 py-2 px-4"
            >
              Join
            </button>
          </form>
        </div>
      </div>
    </section>
  );
}

export default Join;
