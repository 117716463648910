import React, { useState } from "react";
import Header from "../Header/Header";
// import team from "../../assets/about/team.jpeg";
import sc_icon from "../../assets/about/sc_icon.png";
import { IoCall } from "react-icons/io5";
import listIcon from "../../assets/about/f_icon.svg";
import images from "../../assets/about/div.elementor-widget-wrap.png";
import aboutUs from "../../assets/about/aboutUs.jpg"
import imageHeader from "../../assets/header/about.jpg";
function About() {
  const [activeTab, setActiveTab] = useState("mission");

  const handleTabLink = (tab) => {
    setActiveTab(tab);
  };

  return (
    <>
      <Header header="About us" nameComponent="About" image={imageHeader} />

      <section className="py-8">
        <div className="container px-5 lg:w-[90%] xl:w-[80%] mx-auto">
          <div className="grid gap-5 items-center grid-cols-12">
            <div className="col-span-12 lg:col-span-6">
              <div className="p-2  items-center gap-3">
                <img src={sc_icon} alt="sc_icon" />
                <p className=" text-main ">About Our Company</p>
              </div>
              <h2 className="text-black text-4xl font-bold">
                A team of reliable and experienced Contractors
              </h2>
              <p className="py-7">
                Mnawlah is an online platform tailored to the handling solutions
                and storage equipment market in Egypt. The project aims to
                bridge the gap between sellers and buyers by providing a
                user-friendly digital space where businesses can showcase their
                products and customers can find the equipment they need with
                ease. It offers a wide range of solutions, from warehouse
                management tools to lifting and transporting machinery, making
                it the go-to marketplace for anyone in need of storage and
                handling solutions.
              </p>
              <div className="border-b-2">
                <ul className=" flex items-center justify-start gap-2">
                  <li>
                    <button
                      onClick={() => handleTabLink("mission")}
                      className={`${
                        activeTab === "mission"
                          ? "text-white bg-main"
                          : "bg-gray-300 text-black"
                      }  py-2 px-3 lg:px-5 rounded-lg rext-xl font-medium`}
                    >
                      Our Mission
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => handleTabLink("vission")}
                      className={`${
                        activeTab === "vission"
                          ? "text-white bg-main"
                          : "bg-gray-300 text-black"
                      }  py-2 px-3 lg:px-5 rounded-lg rext-xl font-medium`}
                    >
                      Our Vision
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => handleTabLink("value")}
                      className={`${
                        activeTab === "value"
                          ? "text-white bg-main"
                          : "bg-gray-300 text-black"
                      }  py-2 px-3 lg:px-5 rounded-lg rext-xl font-medium`}
                    >
                      Our Value
                    </button>
                  </li>
                </ul>
                <div>
                  {activeTab === "mission" && (
                    <div className="py-4">
                      <p className="px-4 border-l-2 border-main">
                        To empower businesses and individuals by offering a
                        reliable, user-friendly, and dynamic online marketplace
                        for all material handling and storage equipment needs,
                        enhancing operational efficiency and growth in the
                        industry.
                      </p>
                    </div>
                  )}
                  {activeTab === "vission" && (
                    <div className="py-4">
                      <p className="px-4 border-l-2 border-main">
                        To revolutionize Egypt's handling solutions and storage
                        equipment market by providing an efficient, transparent,
                        and accessible digital platform that connects buyers and
                        sellers seamlessly.
                      </p>
                    </div>
                  )}
                  {activeTab === "value" && (
                    <div className="py-4">
                      <p className="px-4 border-l-2 border-main">
                        Mnawlah offers unmatched convenience, accessibility, and
                        a vast array of products, ensuring that customers and
                        businesses can quickly find what they need to optimize
                        their operations, reduce downtime, and maximize
                        productivity.
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex py-5 items-center gap-10">
                <button className="bg-main text-white rounded-md py-3 px-5 hover:bg-[#fdb446] duration-300">
                  Learn more
                </button>
                <div className="flex items-center gap-3">
                  <div className="border-2 border-main rounded-full p-2 lg:p-3">
                    <IoCall className="text-main text-2xl" />
                  </div>
                  <div className="">
                    <p>Want to Discuss:</p>
                    <p className=" text-2xl text-black font-bold">
                      01118182323
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="hidden lg:block col-span-6">
              <img src={aboutUs} alt="team" className="w-full" />
            </div>
          </div>
        </div>
      </section>

      <section className="py-8 bg-[#F7F7F7]">
        <div className="container px-5 lg:w-[90%] xl:w-[80%] mx-auto">
          <div className="grid grid-cols-12 items-center">
            <div className="hidden lg:block col-span-6">
              <img src={images} alt="images" className="w-full" />
            </div>
            {/* 
            <div className="col-span-12 lg:col-span-6 ">
              <h3 className="text-2xl text-black font-bold">
                Practical, Professional, and Market Benefits:
              </h3>
              <p className="py-3">
                Ease of Access: Simplifies the process of finding and purchasing
                equipment for businesses and individuals. Wide Product Range:
                Provides a broad selection of material handling and storage
                solutions to cater to diverse needs. Increased Transparency:
                Offers clear product descriptions, reviews, and prices,
                fostering trust between buyers and sellers. Cost Efficiency:
                Allows buyers to compare prices, ensuring better value for
                money. Time Saving: Streamlines the procurement process,
                reducing the time spent searching for equipment. Reliable
                Suppliers: Connects users with verified, trustworthy sellers in
                the market. Enhanced Market Reach: Provides sellers with access
                to a broader customer base, both locally and regionally.
                User-Friendly Interface: Ensures a smooth and intuitive shopping
                experience for users of all technical backgrounds. Market
                Insights: Offers industry trends and product recommendations,
                helping customers make informed decisions. Scalable Platform:
                Supports the growth of businesses by facilitating easy access to
                required equipment as needs expand.
              </p>
            </div> */}

            <div className="col-span-12 lg:col-span-6 ">
              <h3 className="text-2xl text-black mb-4 font-bold">
                Practical, Professional, and Market Benefits:
              </h3>
              <ul className="flex flex-col gap-2">
                <li className=" flex gap-2">
                  <img src={listIcon} alt="listIcon" />{" "}
                  <p>
                    <span className="font-bold">Ease of Access:</span>{" "}
                    Simplifies the process of finding and purchasing equipment
                    for businesses and individuals.
                  </p>
                </li>
                <li className=" flex gap-2">
                  <img src={listIcon} alt="listIcon" />{" "}
                  <p>
                    <span className="font-bold">Wide Product Range:</span>{" "}
                    Provides a broad selection of material handling and storage
                    solutions to cater to diverse needs.
                  </p>
                </li>
                <li className=" flex gap-2">
                  <img src={listIcon} alt="listIcon" />{" "}
                  <p>
                    <span className="font-bold">Increased Transparency:</span>{" "}
                    Offers clear product descriptions, reviews, and prices,
                    fostering trust between buyers and sellers.
                  </p>
                </li>
                <li className=" flex gap-2">
                  <img src={listIcon} alt="listIcon" />{" "}
                  <p>
                    <span className="font-bold">Cost Efficiency:</span> Allows
                    buyers to compare prices, ensuring better value for money.
                  </p>
                </li>
                <li className=" flex gap-2">
                  <img src={listIcon} alt="listIcon" />{" "}
                  <p>
                    <span className="font-bold">Time Saving:</span> Streamlines
                    the procurement process, reducing the time spent searching
                    for equipment.
                  </p>
                </li>
                <li className=" flex gap-2">
                  <img src={listIcon} alt="listIcon" />{" "}
                  <p>
                    <span className="font-bold">Reliable Suppliers:</span>{" "}
                    Connects users with verified, trustworthy sellers in the
                    market.
                  </p>
                </li>
                <li className=" flex gap-2">
                  <img src={listIcon} alt="listIcon" />{" "}
                  <p>
                    <span className="font-bold">Enhanced Market Reach:</span>{" "}
                    Provides sellers with access to a broader customer base,
                    both locally and regionally.
                  </p>
                </li>
                <li className=" flex gap-2">
                  <img src={listIcon} alt="listIcon" />{" "}
                  <p>
                    <span className="font-bold">User-Friendly Interface:</span>{" "}
                    Ensures a smooth and intuitive shopping experience for users
                    of all technical backgrounds.
                  </p>
                </li>
                <li className=" flex gap-2">
                  <img src={listIcon} alt="listIcon" />{" "}
                  <p>
                    <span className="font-bold">Market Insights:</span> Offers
                    industry trends and product recommendations, helping
                    customers make informed decisions.
                  </p>
                </li>
                <li className=" flex gap-2">
                  <img src={listIcon} alt="listIcon" />{" "}
                  <p>
                    <span className="font-bold">Scalable Platform:</span> Offers
                    Supports the growth of businesses by facilitating easy
                    access to required equipment as needs expand.
                  </p>
                </li>
              </ul>
              <button className="bg-main text-white rounded-md py-3 px-5 hover:bg-[#fdb446] duration-300">
                More Explore
              </button>
            </div>
          </div>
        </div>
      </section>
      {/* 
      <section className="py-8">
        <div className="container px-5 lg:w-[90%] xl:w-[80%] mx-auto gap-20">
          <div className="grid grid-cols-12">
            <div className="col-span-12 lg:col-span-6">
              <div className="w-2/3 mx-auto">
                <div className="relative w-full rounded-tl-3xl  overflow-hidden border-b-4 border-main">
                  <div className="absolute bg-black size-full left-0 top-0 bg-opacity-60">
                    <div className="absolute text-white bottom-3 left-1/2 -translate-x-1/2">
                      <p className=" tect-thin">by Post</p>
                      <p className="font-semibold ">buildexo</p>
                    </div>
                  </div>
                  <img className="w-full" src={hardWork} alt="hardWork" />
                  <img
                    src={person}
                    alt="person"
                    className=" absolute -left-8 bottom-0"
                  />
                </div>
                <p className="text-main py-3">
                  Marketing & Strategy / July 17, 2023
                </p>
                <p></p>
              </div>
            </div>
            <div className="col-span-12 lg:col-span-6">
              <div className="relative w-2/3 rounded-tr-3xl mx-auto overflow-hidden border-b-4 border-main">
                <div className="absolute bg-black size-full left-0 top-0 bg-opacity-60">
                  <div className="absolute text-white bottom-3 left-1/2 -translate-x-1/2">
                    <p className=" tect-thin">by Post</p>
                    <p className="font-semibold ">buildexo</p>
                  </div>
                </div>
                <img className="w-full" src={solution} alt="solution" />
                <img
                  src={person}
                  alt="person"
                  className=" absolute -left-8 bottom-0"
                />
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
}

export default About;
