import React from "react";
import Skeleton from "react-loading-skeleton";

function TraderDetailsLoading() {
  return (
    <div>
      <div className="grid grid-cols-4 gap-4 p-5 ">
        <div className="col-span-full lg:col-span-2">
          <Skeleton className="h-7 w-32 rounded-md" />
          <Skeleton className="h-10 w-FULL rounded-md" />
        </div>
        <div className="col-span-full lg:col-span-2">
          <Skeleton className="h-7 w-32 rounded-md" />
          <Skeleton className="h-10 w-FULL rounded-md" />
        </div>
        <div className="col-span-full lg:col-span-2">
          <Skeleton className="h-7 w-32 rounded-md" />
          <Skeleton className="h-10 w-FULL rounded-md" />
        </div>
        <div className="col-span-full lg:col-span-2">
          <Skeleton className="h-7 w-32 rounded-md" />
          <Skeleton className="h-10 w-FULL rounded-md" />
        </div>
        <div className="col-span-full lg:col-span-2">
          <Skeleton className="h-7 w-52 rounded-md" />
          <Skeleton className="size-[50vh] rounded-md" />
        </div>
        <div className="col-span-full lg:col-span-2">
          <Skeleton className="h-7 w-32 rounded-md" />
          <Skeleton className="size-[50vh] rounded-md" />
        </div>
        <div className="col-span-full lg:col-span-2">
          <Skeleton className="h-7 w-32 rounded-md" />
          <Skeleton className="size-[50vh] rounded-md" />
        </div>

        <div className="flex items-center gap-4 col-span-full">
          <button className="py-2 px-4 outline-none rounded-md bg-main">
            Accept
          </button>
          <button className="py-2 px-4 outline-none rounded-md bg-profileColor">
            Reject
          </button>
        </div>
      </div>
    </div>
  );
}

export default TraderDetailsLoading;
