import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "../../../../Context/UserContext";
import { PulseLoader } from "react-spinners";
import axios from "axios";
import { FaAngleRight } from "react-icons/fa";
import Skeleton from "react-loading-skeleton";

function TraderDataLoading() {

  return (
    <div className="p-10 bg-white rounded-lg">
      <div className="flex flex-col lg:flex-row lg:items-center gap-2 lg:gap-10">
        <p className="flex items-center gap-1">
          <span className="font-medium text-black">Name:</span>{" "}
          <Skeleton className="h-7 w-24 rounded-md" />
        </p>
        <p className="flex items-center gap-1">
          <span className="font-medium text-black">E_mail:</span>{" "}
          <Skeleton className="h-7 w-60 rounded-md" />
        </p>
      </div>
      <Skeleton className="h-7 w-24 my-2 rounded-md" />
      <div className="flex items-center gap-4">
        <button className="py-2 px-4 outline-none rounded-md bg-main">
          Accept
        </button>
        <button className="py-2 px-4 outline-none rounded-md bg-profileColor">
          Reject
        </button>
      </div>
    </div>
  );
}

export default TraderDataLoading;
