import React, { useContext, useState } from "react";
import deletecategory from "../../../../assets/icon.svg";
import { Link } from "react-router-dom";
import { MdEdit } from "react-icons/md";

function BrandData({ brand, length, indexOfBrand }) {
  return (
    <div
      className={`grid grid-cols-3 items-center justify-between gap-10 ${
        length - 1 == indexOfBrand ? "border-b-2" : " "
      }`}
    >
      <tr
        className={`h-20   flex items-center justify-center ${
          length - 1 == indexOfBrand ? "" : " border-b-2"
        }`}
      >
        <td>{brand.enName}</td>
      </tr>
      <tr
        className={`h-20   flex items-center justify-center ${
          length - 1 == indexOfBrand ? "" : " border-b-2"
        }`}
      >
        {" "}
        <td>{brand.arName}</td>
      </tr>
      <tr
        className={`h-20   flex items-center justify-center ${
          length - 1 == indexOfBrand ? "" : " border-b-2"
        }`}
      >
        <td className="flex items-center gap-3">
          <Link
            to={`/dashboard/updatebrand/${brand._id}`}
            className="py-2 px-3 rounded-full "
          >
            <MdEdit />
          </Link>

          {/* <button className="P-2 outline-none">
            <img src={deletecategory} alt="deletecategory" />
          </button> */}
        </td>
      </tr>
    </div>
  );
}

export default BrandData;
