import { createContext, useContext, useState } from "react";
import { UserContext } from "./UserContext";
import { useQuery } from "react-query";
import axios from "axios";
export const ProductContext = createContext()
function ProductContextProvider(props) {
    const [searchQuery, setSearchQuery] = useState(null);
    const [searchProduct, setSearchProduct] = useState(null);
    // const [errorMessage, setErrorMessage] = useState(null);
    const { baseUrl } = useContext(UserContext);

    const { data, productsLoading: isLoading, error } = useQuery("getAllProductsNames", () =>
        axios.get(`${baseUrl}/product?sort=-createdAt"`)
    );
    const filteredProductNames = data?.data?.products?.filter((product) =>
        product.enName.toLowerCase().includes(searchQuery?.toLowerCase())
    )
   const filteredProducts = data?.data?.products?.filter((product) =>
       product.enName.toLowerCase().includes(searchProduct?.toLowerCase()) 
    )

    return (
        <ProductContext.Provider value={{  searchQuery, searchProduct, filteredProductNames, setSearchProduct, allProducts: data?.data?.products, productsErrors: error, initialProductsData:data, productsLoading: isLoading, setSearchQuery, filteredProducts }}>
            {props.children}
        </ProductContext.Provider>
    );
}

export default ProductContextProvider;
