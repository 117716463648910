import { createContext, useContext, useState } from "react";
import { UserContext } from "./UserContext";
import { useQuery } from "react-query";
import axios from "axios";
export const CategoryContext = createContext()
function CategoryContextProvider(props) {
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedFilter, setSelectedFilter] = useState([]);

    const { baseUrl } = useContext(UserContext);

    let { data, isLoading, error } = useQuery("getAllCategoriesForAdmin", () =>
        axios.get(`${baseUrl}/category?sort=-createdAt"`)
    );

    if (error?.message == "Request failed with status code 404") {
        data = null
    }

    return (
        <CategoryContext.Provider value={{ selectedFilter, setSelectedFilter,selectedCategory, setSelectedCategory, categories: data?.data?.categories, categoryIsLoading: isLoading }}>
            {props.children}
        </CategoryContext.Provider>
    );
}

export default CategoryContextProvider;
