import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { UserContext } from "../../../Context/UserContext";
import axios from "axios";
import TraderData from "./TraderData/TraderData";
import TraderDataLoading from "./TraderDataLoading/TraderDataLoading";
import { useNavigate } from "react-router-dom";

function TraderRequests() {
  const { baseUrl, token, setToken, setUserData } = useContext(UserContext);
  const [isDone, setIsDone] = useState(false);
  const [userDeleted, setUserDeleted] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();
  // const { data, isLoading, error } = useQuery("getTraderRequests", () =>
  //   axios.get(`${baseUrl}/user/usersForAdmin`)
  // );
  const getTraderRequests = async () => {
    setIsLoading(true);

    const result = await axios
      .get(`${baseUrl}/user/usersForAdmin`, {
        headers: { authorization: `Hamada__${token}` },
      })
      .catch((err) => {
        setIsLoading(false);

        if (err?.response?.data?.errMass == "TokenExpiredError: jwt expired") {
          localStorage.clear();
          setToken(null);
          setUserData(null);
          navigate("/login");
        }
      });
    
    if (result?.data?.users) {
      setIsLoading(false);
      console.log(result.data.users);
      
      setUsers(result.data.users);
    }
  };

  useEffect(() => {
    getTraderRequests();
  }, []);

  if (isDone && userDeleted?.userName) {
    const index = users?.indexOf(userDeleted);
    if (index !== -1) {
      users?.splice(index, 1);
    }
  }

  return (
    <>
      {isLoading ? (
        <div className="p-5 flex flex-col items-center gap-4">
          {Array(3)
            .fill(0)
            .map((item, index) => (
              <TraderDataLoading key={index} />
            ))}
        </div>
      ) : users?.length ? (
        <div className="p-5 flex flex-col items-center gap-4">
          {users.map((user) => (
            <TraderData
              user={user}
              setIsDone={setIsDone}
              setUserDeleted={setUserDeleted}
            />
          ))}
        </div>
      ) : (
        <div className="size-full flex items-center justify-center">
          <p className="p-5 text-lg font-medium bg-white rounded-lg">
            There is no request right now
          </p>
        </div>
      )}
    </>
  );
}

export default TraderRequests;
