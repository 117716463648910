import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { UserContext } from "../../../../Context/UserContext";
import PreviewImage from "../../../PreviewImage/PreviewImage";
import { PulseLoader } from "react-spinners";
import { BsCloudUpload } from "react-icons/bs";
import axios from "axios";
function UpdateBrand() {
  const { baseUrl, setUserData, setToken, token } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [brand, setBrand] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();

  const getBrand = async () => {
    const { data } = await axios.get(`${baseUrl}/brand/${id}`);
    setBrand(data.brand);
  };

  useEffect(() => {
    getBrand();
  }, []);

  const initialValues = {
    arName: brand?.arName || "",
    enName: brand?.enName || "",
  };

  const validationSchema = Yup.object({
    arName: Yup.string()
      .matches(
        /^[\u0621-\u064A0-9 ]+$/,
        "You have to use just Arabic letters and numbers"
      )
      .min(2, "the minlengt of user name  is 2")
      .max(50, "the maxlength of user name is 20"),
    enName: Yup.string()
      .matches(
        /^[A-Za-z0-9 ]+$/,
        "You have to use just English letters and numbers"
      )
      .min(2, "the minlengt of user name  is 2")
      .max(50, "the maxlength of user name is 20"),
  });

async function updateBrand(values) {
  setIsLoading(true);
  const { arName, enName } = values;

  if (
    (!arName || arName == brand.arName) &&
    (!enName || enName == brand.enName)
  ) {
    setIsLoading(false);
    return 0;
  }

  console.log(values);

  try {
    const { data } = await axios.put(`${baseUrl}/brand/${id}`, values, {
      headers: {
        authorization: `Hamada__${token}`,
      },
    });
    if (data.message == "Done") {
      console.log(data);
      setIsLoading(false);
      setErr(null);
      navigate("/dashboard/brand");
    }
  } catch (err) {
    console.log(err);
    setIsLoading(false);
    if (err?.response?.data?.errMass == "TokenExpiredError: jwt expired") {
      localStorage.clear();
      setToken(null);
      setUserData(null);
      navigate("/login");
    } else {
      setErr(err.response.data.errMass);
    }
  }
}


  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: updateBrand,
  });
  return (
    <div className="size-full">
      <form
        onSubmit={formik.handleSubmit}
        className={`flex flex-col w-2/3 mx-auto mt-14 `}
      >
        <h3 className="mb-3 text-2xl font-bold">Update brand</h3>
        <div className="w-full">
          <label className="font-medium text-black" htmlFor="arName">
            Name in Arabic
          </label>
          <input
            type="text"
            name="arName"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.arName}
            id="arName"
            className={`${
              formik.errors.arName && formik.touched.arName ? "" : "mb-2"
            } w-full text-lg mt-1 outline-none border-2 border-gray-300 focus:border-main py-1 px-2`}
            placeholder="Name in Arabic"
          />
          {formik.errors.arName && formik.touched.arName ? (
            <div className=" mb-2 rounded-md text-red-400 text-lg font-medium px-3 w-full">
              {formik.errors.arName}
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="w-full">
          <label className="font-medium text-black" htmlFor="enName">
            Name in English
          </label>
          <input
            type="text"
            name="enName"
            id="enName"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.enName}
            className={`${
              formik.errors.enName && formik.touched.enName ? "" : "mb-2"
            } w-full text-lg mt-1 outline-none border-2 border-gray-300 focus:border-main py-1 px-2`}
            placeholder="Name in English"
          />
          {formik.errors.enName && formik.touched.enName ? (
            <div className=" mb-2 rounded-md text-red-400 text-lg font-medium px-3 w-full">
              {formik.errors.enName}
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="w-full">
          {err ? (
            <div
              className={` rounded-md mb-2 text-red-400 text-lg font-medium px-3 w-full`}
            >
              {err}
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="w-full">
          {isLoading ? (
            <button
              disabled
              className="py-2 px-4 outline-none bg-main text-white rounded-md"
            >
              <PulseLoader
                color="#fff"
                cssOverride={{}}
                loading
                margin={2}
                size={7}
                speedMultiplier={1}
              />
            </button>
          ) : (
            <button
              type="submit"
              className="py-2 px-4 outline-none bg-main text-white rounded-md"
            >
              Update brand
            </button>
          )}
        </div>
      </form>
    </div>
  );
}

export default UpdateBrand;
