import React, { useContext, useState } from "react";
import header1 from "../../../assets/3.jpg";
import header2 from "../../../assets/2.jpg";
import header3 from "../../../assets/5.jpg";
import { MdOutlineSearch } from "react-icons/md";
import { useQuery } from "react-query";
import axios from "axios";
import { UserContext } from "../../../Context/UserContext";
import { Link, useNavigate } from "react-router-dom";
import { ProductContext } from "../../../Context/ProductContext";

function Header() {
  const {
    searchQuery,
    setSearchQuery,
    filteredProductNames,
    setSearchProduct,
  } = useContext(ProductContext);
  const navigate = useNavigate();

  const handleSearchSubmit = () => {
    if (searchQuery?.length && filteredProductNames?.length) {
      setSearchProduct(searchQuery);
      setSearchQuery("");
      navigate("products");
    }
  };

  const [activeTowns, setActiveTowns] = useState(false);
  const [activestudio, setActiveStudio] = useState(false);
  const [activeModern, setActiveModern] = useState(false);

  const activestudioLink = () => {
    setActiveStudio(!activestudio);
  };
  const activeTownLink = () => {
    setActiveTowns(!activeTowns);
  };
  const activeModernLink = () => {
    setActiveModern(!activeModern);
  };

  return (
    <header className="bg-headerColor h-screen flex items-center justify-center">
      <div className="container lg:w-[90%] max-w-screen-xl mx-auto px-5">
        <div className="grid grid-cols-12">
          <div className="col-span-12  flex flex-col justify-center gap-7 lg:col-span-6">
            <h1 className="text-2xl text-center font-medium lg:text-start md:font-semibold md:3xl lg:font-bold xl:text-4xl text-main">
              <span className="text-socend">The First </span> Handling <br/> Equipment
              Marketplace
              <span className="text-socend"> In Africa</span>
            </h1>
            <div className="py-5 lg:py-16">
              <label className="text-lg md:hidden">Enter Keyword</label>
              <div className="relative">
                <div className="lg:absolute translate-x-0 w-fit flex items-center justify-center gap-2 lg:gap-10 px-2 lg:px-10 bg-white top-0 lg:top-1/2 lg:-translate-y-1/2 left-0 py-2 rounded-md">
                  <div className="hidden md:flex flex-col items-start justify-start min-w-fit">
                    <p className="text-[#969696] text-sm md:text-[16px]">
                      Keyword
                    </p>
                    <p className="text-lg">Enter Keyword</p>
                  </div>

                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSearchSubmit();
                    }}
                    className="flex items-center gap-2 lg:gap-10 "
                  >
                    <label htmlFor="searchProduct" className="">
                      <MdOutlineSearch className="text-xl hidden md:block  lg:text-4xl" />
                    </label>
                    <div className="relative">
                      <input
                        type="text"
                        id="searchProduct"
                        placeholder="Enter Keyword"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="outline-0 md:border-l-2 pl-2 lg:pl-10 text-sm lg:text-xl py-2"
                      />
                      {!filteredProductNames?.length || !searchQuery ? (
                        ""
                      ) : (
                        <div className="absolute top-full left-0 w-full bg-white overflow-y-auto scrollbar-thin scrollbar-webkit flex flex-col gap-1 border-2 max-h-[20vh] rounded-md p-2">
                          {filteredProductNames.map((product) => {
                            return (
                              <Link
                                to={`product/${product._id}`}
                                className="py-1 px-2 rounded-md cursor-pointer text-main hover:text-white hover:bg-main font-medium duration-300"
                              >
                                {product.enName}
                              </Link>
                            );
                          })}
                        </div>
                      )}
                    </div>
                    <div className=" flex items-center gap-1 lg:gap-3">
                      <Link
                        to={`products`}
                        className="bg-transparent rounded-md text-sm lg:text-[16px] py-1 px-2 lg:px-5 border-2 hover:border-transparent hover:bg-main hover:text-white duration-300"
                      >
                        Filter
                      </Link>
                      <button
                        type="submit"
                        className="bg-main text-white rounded-md text-sm lg:text-[16px] py-1 px-2 lg:px-5 border-2 border-transparent hover:bg-[#fdb446] duration-300"
                      >
                        Search
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="flex items-center gap-2 ">
              <span>Popular Search</span>
              <button
                onClick={() => activeModernLink()}
                className={`${
                  activeModern
                    ? "bg-main text-white border-none"
                    : "text-button border-2 bg-white"
                }  rounded-3xl hover:text-white hover:bg-main duration-300 hover:border-none text-sm p-1`}
              >
                Modern Villa
              </button>
              <button
                onClick={() => activestudioLink()}
                className={`${
                  activestudio
                    ? "bg-main text-white border-none"
                    : "text-button border-2 bg-white"
                } rounded-3xl  hover:text-white hover:bg-main duration-300 hover:border-none text-sm  p-1`}
              >
                Studio Apartment
              </button>
              <button
                onClick={() => activeTownLink()}
                className={`${
                  activeTowns
                    ? "bg-main text-white border-none"
                    : "text-button border-2 bg-white"
                } rounded-3xl  hover:text-white hover:bg-main duration-300 hover:border-none text-sm  p-1`}
              >
                Town House
              </button>
            </div>
          </div>
          <div className="hidden lg:block lg:col-span-6">
            <div className="flex items-center mt-16">
              <div className="w-1/2 p-5 mt-12">
                <img
                  src={header1}
                  className="w-full rounded-3xl"
                  alt="Header 1"
                />
              </div>
              <div className="w-1/2  flex flex-col">
                <div className="flex-none  p-5">
                  <img
                    src={header2}
                    className="w-full rounded-3xl "
                    alt="Header 2"
                  />
                </div>
                <div className="flex-none p-5">
                  <img
                    src={header3}
                    className="w-full rounded-3xl"
                    alt="Header 3"
                  />
                </div>
              </div>
            </div>
            {/* <img className="w-full" src={mainImage} alt="mainImage" /> */}
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
