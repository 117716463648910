import React, { useContext, useEffect, useState } from "react";

import { useQuery } from "react-query";
import { UserContext } from "../../../Context/UserContext";
import axios from "axios";
import ProductRequestData from "./ProductRequestData/ProductRequestData";
import ProductRequestDataLoading from "./ProductRequestDataLoading/ProductRequestDataLoading";
import { useNavigate } from "react-router-dom";

function ProductRequests() {
  const { baseUrl, token, setToken, setUserData } = useContext(UserContext);
  const [isDone, setIsDone] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [producrDeleted, setProducrDeleted] = useState(null);
  const [products, setProducts] = useState([]);

  const navigate = useNavigate();

  const getProductRequests = async () => {
    setIsLoading(true);
    const data  = await axios
      .get(`${baseUrl}/product/productRequests`, {
        headers: { authorization: `Hamada__${token}` },
      })
      .catch((err) => {
        setIsLoading(false);

        if (err?.response?.data?.errMass == "TokenExpiredError: jwt expired") {
          localStorage.clear();
          setToken(null);
          setUserData(null);
          navigate("/login");
        }
      });
    if (data?.data?.products) {
      setIsLoading(false);
      setProducts(data.data.products);
    }
  };


  useEffect(() => {
    getProductRequests()
  },[])

  if (isDone && producrDeleted?.enName) {
    const index = products?.indexOf(producrDeleted);
    if (index !== -1) {
      products?.splice(index, 1); // Removes 1 item at the found index
    }
    setProducrDeleted(null);
    setIsDone(false);
  }

  return (
    <>
      {isLoading ? (
        <div className="p-5">
          <table className="bg-white w-[90%] mx-auto rounded-md overflow-hidden">
            <thead className="grid grid-cols-4 gap-10 items-center justify-between w-full">
              <th className="py-3 col-span-1 border-b-2 flex items-center justify-center">
                <td>Name og trader</td>
              </th>
              <th className="py-3 col-span-1 border-b-2 flex items-center justify-center">
                {" "}
                <td>Stock</td>
              </th>
              <th className="py-3 col-span-1 border-b-2 flex items-center justify-center">
                {" "}
                <td>Price</td>
              </th>
              <th className="py-3 bg-profileColor col-span-1 border-b-2 flex items-center justify-center">
                {" "}
                <td>Action</td>
              </th>
            </thead>
            <tbody className=" w-full">
              <ProductRequestDataLoading />
            </tbody>
          </table>
        </div>
      ) : products?.length ? (
        <div className="p-5">
          <table className="bg-white w-[90%] mx-auto rounded-md overflow-hidden">
            <thead className="grid grid-cols-4 gap-10 items-center justify-between w-full">
              <th className="py-3 col-span-1 border-b-2 flex items-center justify-center">
                <td>Name og trader</td>
              </th>
              <th className="py-3 col-span-1 border-b-2 flex items-center justify-center">
                {" "}
                <td>Stock</td>
              </th>
              <th className="py-3 col-span-1 border-b-2 flex items-center justify-center">
                {" "}
                <td>Price</td>
              </th>
              <th className="py-3 bg-profileColor col-span-1 border-b-2 flex items-center justify-center">
                {" "}
                <td>Action</td>
              </th>
            </thead>
            <tbody className=" w-full">
              {products.map((product) => (
                <ProductRequestData
                  key={product._id}
                  product={product}
                  length={products.length}
                  indexOfProduct={products.indexOf(product)}
                  setIsDone={setIsDone}
                  setProducrDeleted={setProducrDeleted}
                />
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="size-full flex items-center justify-center">
          <p className="p-5 text-lg font-medium bg-white rounded-lg">
            There is no request right now
          </p>
        </div>
      )}
    </>
  );
}

export default ProductRequests;
