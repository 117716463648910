import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../Context/UserContext";
import axios from "axios";
import BrandData from "./BrandData/BrandData";
import BrandLoading from "./BrandLoading/BrandLoading";

function Brand() {
  const { baseUrl, token, setToken, setUserData } = useContext(UserContext);
  const [brands, setBrands] = useState([]);
  const [brandsLoading, setBrandsLoading] = useState(false);

  const getAllBrands = async () => {
    setBrandsLoading(true);
    const { data } = await axios.get(`${baseUrl}/brand?sort=-createdAt`);
    console.log(data.brands);
    setBrands(data.brands);
    setBrandsLoading(false);
  };

  useEffect(() => {
    getAllBrands();
  }, []);

  return (
    <>
      {brands?.length == 0 && !brandsLoading ? (
        <div className="size-full flex items-center justify-center">
          <p className="p-5 text-lg font-medium bg-white rounded-lg">
            There is no Category till now
          </p>
        </div>
      ) : (
        <div className="p-5">
          <table className="bg-white w-[90%] mx-auto rounded-md overflow-hidden">
            <thead className="grid grid-cols-3 gap-10 items-center justify-between w-full">
              <th className="py-3 col-span-1 border-b-2 flex items-center justify-center">
                {" "}
                <td>Name in English</td>
              </th>
              <th className="py-3 col-span-1 border-b-2 flex items-center justify-center">
                <td>Name in Arabic</td>
              </th>
              <th className="py-3 bg-profileColor col-span-1 border-b-2 flex items-center justify-center">
                <td>Action</td>
              </th>
            </thead>
            <tbody className=" w-full">
              {brandsLoading ? (
                <BrandLoading/>
              ) : (
                brands.map((brand) => (
                  <BrandData
                    brand={brand}
                    length={brands.length}
                    indexOfBrand={brands.indexOf(brand)}
                  />
                ))
              )}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
}

export default Brand;
