import React from "react";
import Skeleton from "react-loading-skeleton";

function FelterLoading({header}) {
  return (
    <div className="py-5 px-2 border-2 rounded-lg">
      <h2 className="font-bold px-5 mb-2 text-2xl">{header}</h2>
      <Skeleton className=" my-1 h-12 mt-5 rounded-md" />
    </div>
  );
}

export default FelterLoading;
