import React, { useContext, useState } from "react";
import deletecategory from "../../../../assets/icon.svg";
import { Link } from "react-router-dom";
import { AiOutlineCaretDown, AiOutlineCaretUp } from "react-icons/ai";
import { MdEdit } from "react-icons/md";
function CategoryData({ category, length, indexOfCategory }) {
  const [isOpenedSubcategory, setIsOpenedSubcategory] = useState(false);

  return (
    <div
      className={`grid grid-cols-4 items-center justify-between gap-10 ${
        length - 1 == indexOfCategory ? "border-b-2" : " "
      }`}
    >
      <tr
        className={`h-20 col-span-1   flex items-center justify-center ${
          length - 1 == indexOfCategory ? "" : " border-b-2"
        }`}
      >
        <td>{category.enName}</td>
      </tr>
      <tr
        className={`h-20 col-span-1   flex items-center justify-center ${
          length - 1 == indexOfCategory ? "" : " border-b-2"
        }`}
      >
        {" "}
        <td>
          <img
            src={category.image.secure_url}
            alt={category.enName}
            className="size-12 object-cover"
          />
        </td>
      </tr>
      <tr
        className={`h-20 col-span-1   flex items-center justify-center ${
          length - 1 == indexOfCategory ? "" : " border-b-2"
        }`}
      >
        <td className=" w-full">
          <div
            onClick={() => setIsOpenedSubcategory(!isOpenedSubcategory)}
            className={`p-2 block text-lg select-none w-full border-2 outline-none  ${
              isOpenedSubcategory ? "border-main " : ""
            } relative text-black `}
          >
            Subcategories
            {length - 1 == indexOfCategory || length - 2 == indexOfCategory ? (
              !isOpenedSubcategory ? (
                <AiOutlineCaretUp className="text-lg absolute top-1/2 text-black -translate-y-1/2 right-[5%]" />
              ) : (
                <AiOutlineCaretDown
                  className={`text-lg absolute top-1/2 text-main -translate-y-1/2 right-[5%]`}
                />
              )
            ) : isOpenedSubcategory ? (
              <AiOutlineCaretUp className="text-lg absolute top-1/2 text-main -translate-y-1/2 right-[5%]" />
            ) : (
              <AiOutlineCaretDown
                className={`text-lg absolute top-1/2 text-black -translate-y-1/2 right-[5%]`}
              />
            )}
            <div
              className={`${isOpenedSubcategory ? "block" : "hidden"} ${
                length - 1 == indexOfCategory || length - 2 == indexOfCategory
                  ? "z-20 bottom-full"
                  : "top-full z-10"
              } absolute max-h-[25vh]  min-w-full p-2 overflow-y-auto scrollbar-thin scrollbar-webkit   flex-col gap-1 border-2 bg-white  left-1/2 -translate-x-1/2  border-main text-center`}
            >
              {category?.subcategory?.length ? (
                category.subcategory.map((subcategory) => (
                  <div
                    key={subcategory._id}
                    className="py-1 rounded-md flex items-center px-2 justify-between gap-1 "
                  >
                    {subcategory.enName}
                    <div className="">
                      <Link
                        to={`/dashboard/${category._id}/updatesubcategory/${subcategory._id}`}
                      >
                        <MdEdit />
                      </Link>
                      {/* <button>
                      <img src={deletecategory} alt="deletecategory" />
                    </button> */}
                    </div>
                  </div>
                ))
              ) : (
                <p>There is no subcategories in this category</p>
              )}
            </div>
          </div>
        </td>
      </tr>
      <tr
        className={`h-20 col-span-1   flex items-center justify-center ${
          length - 1 == indexOfCategory ? "" : " border-b-2"
        }`}
      >
        <td className="flex items-center gap-5">
          <Link
            to={`/dashboard/updatecategory/${category._id}`}
            className="py-2 px-3 rounded-full"
          >
            <MdEdit />
          </Link>

          {/* <button className="P-2 outline-none">
            <img src={deletecategory} alt="deletecategory" />
          </button> */}
        </td>
      </tr>
    </div>
  );
}

export default CategoryData;
