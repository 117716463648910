import React, { useContext, useState } from "react";
import Skeleton from "react-loading-skeleton";

function BrandLoading() {
  const arr = Array(5).fill(0);
  return (
    <>
      {arr.map((item, index) => (
        <div
          key={index}
          className={`grid grid-cols-3 items-center justify-between gap-10 ${
            arr.length - 1 == index ? "border-b-2" : ""
          } `}
        >
          <tr
            className={`h-20   flex items-center justify-center ${
              arr.length - 1 == index ? "" : "border-b-2"
            }`}
          >
            <td>
              {" "}
              <Skeleton className="h-7 w-20 rounded-md" />
            </td>
          </tr>
          <tr
            className={`h-20   flex items-center justify-center ${
              arr.length - 1 == index ? "" : "border-b-2"
            }`}
          >
            {" "}
            <td>
              {" "}
              <Skeleton className="h-7 w-20 rounded-md" />
            </td>
          </tr>
          <tr
            className={`h-20   flex items-center justify-center ${
              arr.length - 1 == index ? "" : "border-b-2"
            }`}
          >
            <td>
              <Skeleton className="h-7 w-20 rounded-md" />
            </td>
          </tr>
        </div>
      ))}
    </>
  );
}

export default BrandLoading;
