import React, { useContext, useState } from "react";
import deletecategory from "../../../../assets/icon.svg";
import { Link } from "react-router-dom";
import { AiOutlineCaretDown, AiOutlineCaretUp } from "react-icons/ai";
import { MdEdit } from "react-icons/md";
import Skeleton from "react-loading-skeleton";
function CategoryLoading() {
  const arr = Array(5).fill(0);

  return (
    <>
      {arr.map((item, index) => (
        <div
          key={index}
          className={`grid grid-cols-4 items-center justify-between gap-10 ${
            arr.length - 1 == index ? "border-b-2" : ""
          } `}
        >
          <tr
            className={`h-20 col-span-1  ${
              arr.length - 1 == index ? "" : "border-b-2"
            }  flex items-center justify-center`}
          >
            <td>
              <Skeleton className="h-7 w-20 rounded-md" />
            </td>
          </tr>
          <tr
            className={`h-20 col-span-1  ${
              arr.length - 1 == index ? "" : "border-b-2"
            }  flex items-center justify-center`}
          >
            <td>
              <Skeleton className="size-12" />
            </td>
          </tr>
          <tr
            className={`h-20 col-span-1  ${
              arr.length - 1 == index ? "" : "border-b-2"
            }  flex items-center justify-center`}
          >
            <td className=" w-full">
              <Skeleton className="h-10 w-full rounded-md" />
            </td>
          </tr>
          <tr
            className={`h-20 col-span-1  ${
              arr.length - 1 == index ? "" : "border-b-2"
            }  flex items-center justify-center`}
          >
            <td className="flex items-center gap-5">
              <Skeleton className="h-7 w-20 rounded-md" />
            </td>
          </tr>
        </div>
      ))}
    </>
  );
}

export default CategoryLoading;
